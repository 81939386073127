<template>
  <div>
    <v-dialog v-model="ModalEdit" persistent max-width="80%">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Edit Data</v-toolbar-title>
        </v-toolbar>

        <div class="pa-5">
          <div class="mb-5">
            <h3>EDIT ASN YANG MENJABAT</h3>
          </div>

          <div>
            <v-text-field
              v-model="unor_name"
              label="Unit"
              outlined
              dense
              readonly
              filled
            ></v-text-field>

            <v-text-field
              v-model="SData.title"
              label="Nama Jabatan"
              outlined
              dense
              readonly
              filled
            ></v-text-field>

            <v-divider class="mb-5 mt-n2"></v-divider>

            <v-text-field
              v-model="SData.asn.nip"
              label="Nomor Induk Pegawai"
              :loading="LCari"
              outlined
              dense
            >
              <template v-slot:append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      depressed
                      small
                      color="primary"
                      class="mt-n2"
                      v-bind="attrs"
                      v-on="on"
                      @click="cari()"
                    >
                      <v-icon> mdi-account-search </v-icon>
                    </v-btn>
                  </template>
                  <span>Cari</span>
                </v-tooltip>
              </template>
            </v-text-field>

            <v-row dense class="mb-n6">
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="SData.asn.nama"
                  label="Nama ASN"
                  outlined
                  dense
                  readonly
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="2">
                <v-menu
                  v-model="tmtJabatanPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tmtJabatan"
                      label="TMT Jabatan"
                      append-icon="mdi-calendar"
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tmtJabatan"
                    @input="tmtJabatanPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="2">
                <v-menu
                  v-model="tmtPelantikanPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tmtPelantikan"
                      label="TMT Pelantikan"
                      append-icon="mdi-calendar"
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tmtPelantikan"
                    @input="tmtPelantikanPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <!-- Surat Keputusan -->
            <v-row dense>
              <v-col cols="12">
                <span>Surat Keputusan (SK)</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  hint="Pejabat Yang Menandatangani"
                  v-model="skPejabat"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  hint="Nomor"
                  v-model="skNomor"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="skTanggalPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="skTanggal"
                      hint="Tanggal"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="skTanggal"
                    @input="skTanggalPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-divider class="mb-5 mt-2"></v-divider>

            <v-row dense>
              <v-col cols="12" md="6">
                <v-select
                  label="Pola Kerja"
                  :items="iPK"
                  item-value="id"
                  item-text="nama"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  label="Shift Kerja"
                  :items="iShift"
                  item-value="id"
                  item-text="nama"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </div>

          <v-divider class="my-3"></v-divider>

          <div class="text-right">
            <v-btn text @click="closeModal()" class="mr-2"> Batal </v-btn>

            <v-btn color="success" @click="edit()" v-if="LBtn">
              <v-icon left> mdi-content-save </v-icon>
              Simpan
            </v-btn>

            <v-btn color="success" loading v-else>
              <v-icon left> mdi-content-save </v-icon>
              Simpan
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import SView from "@/store/anjab/mutasi/view";
import SEdit from "@/store/anjab/mutasi/edit";

export default {
  computed: {
    ModalEdit: {
      get() {
        return SEdit.state.ModalEdit;
      },
      set(value) {
        SEdit.commit("TModal", value);
      },
    },

    SData: {
      get() {
        return SEdit.state.data;
      },

      set(value) {
        console.log(value);
      },
    },
  },

  watch: {
    async ModalEdit() {},
  },

  data: () => ({
    token: Cookie.get("token"),
    unor_name: localStorage.getItem("unor"),
    LBtn: true,
    LCari: false,

    unor_nama: "",
    idASN: "",

    tmtJabatanPicker: false,
    tmtPelantikanPicker: false,
    skTanggalPicker: false,

    tmtJabatan: new Date().toISOString().substr(0, 10),
    tmtPelantikan: new Date().toISOString().substr(0, 10),
    skTanggal: new Date().toISOString().substr(0, 10),

    iPK: [
      {
        id: "wfo",
        nama: "WFO (Work From Office)",
      },
      {
        id: "wfh",
        nama: "WFH (Work From Home)",
      },
    ],

    iShift: [
      {
        id: "pagi",
        nama: "Pagi",
      },
      {
        id: "siang",
        nama: "Siang",
      },
      {
        id: "malam",
        nama: "Malam",
      },
    ],
  }),

  methods: {
    reset() {
      this.idASN = "";
    },

    async edit() {
      this.LBtn = false;
      let data = {
        idPeta: this.SData.idPeta,
        idASN: this.idASN,
      };

      const url = process.env.VUE_APP_API + "petajab/asn";
      this.axios
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.LBtn = true;
          if (res.data.success) {
            SView.commit("refreshData", true);
            SView.commit("alert", res.data.message);
            SView.commit("berhasilAlert", true);
            SView.commit("gagalAlert", false);
            SView.commit("success", res.data.success);
          } else {
            SView.commit("refreshData", true);
            SView.commit("alert", res.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", res.data.success);
          }

          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout();
          } else if (error.response) {
            SView.commit("refreshData", true);
            SView.commit("alert", error.response.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.LBtn = true;
            this.closeModal();
          } else {
            SView.commit("refreshData", true);
            SView.commit("alert", error.response.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.LBtn = true;
            this.closeModal();
          }
        });
    },

    cari() {
      this.LCari = true;

      const url = process.env.VUE_APP_API + "petajab/apr/" + this.SData.asn.nip;
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.LCari = false;
          console.log(res);
          this.idASN = res.data.mapData[0].idASN;
          this.SData.asn.nip = res.data.mapData[0].asn.nip;
          this.SData.asn.nama = res.data.mapData[0].asn.nama;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout();
          } else if (error.response) {
            SView.commit("refreshData", true);
            SView.commit("alert", error.response.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.LCari = true;
          } else {
            SView.commit("refreshData", true);
            SView.commit("alert", error.response.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.LCari = true;
          }
        });
    },

    async closeModal() {
      await SView.commit("refreshData", true);
      this.reset();
      this.ModalEdit = false;
    },
  },
};
</script>